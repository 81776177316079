.contact-background {
  color: #222;
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    font-weight: 900;
    /* font-family: "Pilat-Extended",sans-serif; */
    position: fixed;
  
    top: 0;
    left:0;
    /* transform: translate(-50%, -50%); */
    margin-left: auto;
    margin-right: auto;
    width: calc(100%);
    overflow: hidden;
    z-index: -1;
    font-family: Resolve;

}

.contact-logo {
  width:70%;
}

.social-media-logo{
width: 7vw;
}

.contact-social-media{
  display: flex;
  flex-direction: row;
}

.social-media-logo-separator{
  width:5vw;
}