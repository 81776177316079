

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  /* mix-blend-mode: difference; */
  /* pointer-events: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 80px;
  z-index: 40;

}

.logo-black {
  height: 170px;
  width: auto;
  /* mix-blend-mode: difference;  */
  /* Mezcla de colores en modo diferencia */
  /* filter: invert(1);  */
  /* filter:none; */
  /* Inversión de colores en la imagen */
  z-index: 40;
}
.logo-white{
  height: 62px;
  width: auto;
  /* mix-blend-mode: difference;  */
  /* Mezcla de colores en modo diferencia */
  /* filter: invert(1);  */
  /* filter:none; */
  /* Inversión de colores en la imagen */
  z-index: 40;

}