.backgroundtext{
  color: #222;
  background-color: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
    font-weight: 900;
    /* font-family: "Pilat-Extended",sans-serif; */
    position: fixed;
  
    top: 0;
    left:0;
    /* transform: translate(-50%, -50%); */
    margin-left: auto;
    margin-right: auto;
    width: calc(100%);
    overflow: hidden;
    z-index: -1;
    font-family: Resolve;

    
}

.backgroundtext-text{
  position: fixed;
  display: block;
  width: 100%;
  text-align: center;
  color: #222;
    font-size: 18vw;
    font-weight: 900;
    
}