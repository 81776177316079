.about-title-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.about-director-name{
opacity: 0;
transition: opacity 0.5s ease-in-out ;
font-family: Resolve;
color: white;
background-color: black;
width: 100vw;
padding-top: 10vh;
padding-bottom: 10vh;
display: flex;
justify-content: center;
text-align: center;
}

.about-director-name-visible {
  opacity: 1;
}

.about-bio-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}

.about-text{
  font-family: Poppins;
  font-size: 1.4vw;
  color: white;
  /* -webkit-text-stroke: 2px rgb(0, 0, 0); Ancho y color del contorno */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); /* Agrega la sombra aquí */
  margin-right: 10vw ;
  text-align: justify;
}

.about-img-container{
width: 50vw;
}

.about-img{
  margin-left: 10vw;
  width: 50vw;
}

@media (max-width: 1000px) {
  .about-bio-container {
    flex-direction: column;
    height: auto;
  }
  .about-media-separador{
    height: 10vh;
  }
  .about-text{
    font-family: Resolve;
    font-size: 1rem;
    color: white;
    margin-right: 10vw ;
    margin-left : 10vw ;
  }
  .about-img-container{
    width: 90vw;
    }
    .about-img{
      margin-left: 0;
      width: 90vw;
    }

}
