
.video-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 10vh;
}

.video-item {
  position: relative;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 500px; */
  min-width: 400px; /* Cambia el tamaño máximo según lo necesario */
  margin: 0 auto;
  overflow: visible;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%; /* Proporción 16:9 */
}

.video-thumbnail img,
.hovered-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hovered-video {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

/* .hovered-selection {
  background-color: rgb(255, 255, 255, 0.5);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
} */

.video-thumbnail:hover .hovered-video {
  opacity: 1;
  pointer-events: initial;
  z-index: 102;
}

.video-thumbnail:hover .video-thumbnail {
  
  z-index: 101;
}

.video-info {
  display: none;
  padding: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s;
}

.video-item:hover .video-info {
  display: block;
}


.card-title,
.card-subtitle,
.card-director {
  font-family: Resolve;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Agrega la sombra aquí */
  color: white !important;
}

.card-title{
  width: 35vw;
  min-width: 410px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.card-subtitle{
  width: 35vw;
  min-width: 410px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.card-big-title,
.card-big-border-title{
  flex: 1; /* Distribuimos el espacio disponible de manera equitativa */
  margin: 0;
  font-family: Resolve;

  font-size: 4rem;
  white-space: normal;
}

.card-big-title{
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  margin-left: 10%;
  color: rgb(0, 0, 0);
}

.card-big-border-title{
  z-index: 3;
  opacity: 1;
  /* border:  solid rgb(255, 0, 0); */
  /* padding: -1px; */
  position: absolute;
  left: 0;
  /* margin-left: 10%; */
  color: transparent; /* Establece el color de relleno en transparente */
  -webkit-text-stroke: 2px rgb(255, 0, 0); /* Ancho y color del contorno */
  -webkit-font-smoothing: antialiased; /* Mejora la suavidad del contorno en ciertos navegadores */
}