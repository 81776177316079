.video-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%; 

  /* El video ocupará el 80% de la altura de la pantalla */
}

.video-main {
  width: 100%; 
  /* El video ocupará el 80% del ancho de su contenedor */
  /* max-width: 100%;  */
  /* Asegura que no se desborde del contenedor */
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.3s ease;
}
