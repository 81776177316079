/* DirectorForm.css */

.director-form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  border-radius: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vh;
  overflow-y: scroll;
  
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
  color: #333;
}

.select-director{
  font-size: 18px;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-actions {
  text-align: center;
}

.form-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-delete:hover {
  opacity: 0.8;
}

/* Estilos para la imagen previa */
.image-preview {
  width: 100%;
  max-height: 200px;
  margin-top: 10px;
}

/* Estilos para el enlace generado */
.generated-link {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 0em; /* Ancho de la barra de desplazamiento (ajusta según tus preferencias) */
}

::-webkit-scrollbar-track {
  background: transparent; /* Color del fondo de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color de la manija de la barra de desplazamiento */
  border: none; /* Elimina el borde de la manija */
}