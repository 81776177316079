

.image-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  transform: translate(25%, 30%);
  margin-left: auto;
  margin-right: auto;
  /* width: calc(50%); */
  overflow: hidden;
  display: block;
  z-index: 2;
  opacity: 0; /* Inicialmente oculto */
  transition: opacity 0.4s ease, transform 0.4s ease; /* Transiciones para opacidad y transform */
  
}

.image {
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit:cover;
  
  

}

.image-container {
  /* max-height: 500px; */
  width: 50%;
  /* max-height: 600px; */
  position: relative; /* Requerido para posicionar la imagen internamente */
  overflow: hidden;
  
}

.name-container {
  display: flex; /* Utilizamos flexbox para alinear los elementos */
  align-items: center; /* Centramos verticalmente los elementos */
  position: relative;
  margin-bottom: 15px; /* Agregamos un margen inferior entre los contenedores */
  text-decoration: none;
  width: 100%;
  /* display: inline-block; */

}

.name,
.border-name {
  flex: 1; /* Distribuimos el espacio disponible de manera equitativa */
  margin: 0;
  font-family: Resolve;

  font-size: 10vh;
}

.name {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  margin-left: 10%;
  color: white;
}

.border-name {
  z-index: 3;
  opacity: 0;
  /* border:  solid rgb(255, 0, 0); */
  padding: -1px;
  position: absolute;
  left: 0;
  margin-left: 10%;
  color: transparent; /* Establece el color de relleno en transparente */
  -webkit-text-stroke: 2px white; /* Ancho y color del contorno */
  -webkit-font-smoothing: antialiased; /* Mejora la suavidad del contorno en ciertos navegadores */
  
}

.name-container:hover .border-name {
  opacity: 100;
}

.image-overlay.show {
  opacity: 1; /* Mostrar con opacidad completa */
  transform: translate(25%, 10%);
   /* Cambiar la posición cuando esté visible */
}

@media (max-width: 768px) {
  /* Media Query para dispositivos con un ancho mínimo de 768px (tabletas y PCs) */
  .name,
  .border-name {
    flex: 1; /* Distribuimos el espacio disponible de manera equitativa */
    margin: 0;
    font-family: Resolve;

    font-size: 2.5rem;
    margin-left: 1rem;
  }
  .image-container{
   width: 90%; 
  }
  .image-overlay.show {
    opacity: 1; /* Mostrar con opacidad completa */
    transform: translate(5%, 10%);
     /* Cambiar la posición cuando esté visible */
  }
  .image-overlay{
    transform: translate(5%, 30%);
  }
}


