/* styles.css - Archivo de estilos principal */

/* @import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap'); */
/* Agrega la fuente desde Google Fonts o cualquier otro proveedor */
@font-face {
  font-family: Resolve;
  src: url('../../fonts/Resolve-BlackExt.otf');
}

@font-face {
  font-family: Poppins;
  src: url('../../fonts/Poppins-Medium.ttf') format('truetype');
  /* Otras propiedades de fuente */
}

body {
  font-family: Resolve; /* Reemplaza "FontName" con el nombre de la fuente */

}
body::-webkit-scrollbar {
  width: 0; /* Ancho de la barra de desplazamiento */
  background-color: transparent; /* Color de fondo de la barra de desplazamiento */
}

ul, ol {
  list-style: none; /* Elimina los puntos de las listas */
  white-space: nowrap;
}
li {
  list-style: none; /* Elimina los puntos de las listas */
  

}
a{
  text-decoration: none;
}

.uppercase{
  text-transform: uppercase;
}
.separador{
  height: 5vh;
  }
  @media (min-width: 768px) {
    /* Media Query para dispositivos con un ancho mínimo de 768px (tabletas y PCs) */
    .separador{
      height: 10vh;
      }
  }
