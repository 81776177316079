/* @font-face {
  font-family: 'Pilat-Extended';
  src: url('../../fonts/pilat-extended.woff2') format('woff2'), /* Agrega diferentes formatos si es necesario */
/* } */ 
.separador-directors{
height: 120px;
}

.background {
  flex: 1;
  background-color: black;
  position:absolute;
  height: 100%;
  width: 100%;
}
.background-color{
  background-color: black;
  position: fixed;
  height: 100%;
  width: 100%;
}

.background-text {
  color: #222;
    font-size: 10vw;
    font-weight: 900;
    /* font-family: "Pilat-Extended",sans-serif; */
    position: fixed;
  
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
    overflow: hidden;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    z-index: 0;
    font-family: Resolve;
    
}