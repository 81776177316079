/* .music-video-director-container{} */
.absolute-music-container{
background-color: black !important;
height: 100vh;
width: 100vw;
position: fixed;
z-index: -1;
}

.music-video-director{
  width: 100%;
  text-align: center;
  font-family: Resolve;
  color: transparent;
  -webkit-text-stroke: 2px #ffffff; /* Ancho y color del contorno */
  -webkit-font-smoothing: antialiased; /* Mejora la suavidad del contorno en ciertos navegadores */
}

.cards-container{
  display: flex;
  flex-wrap:wrap;
  justify-content: center;

}

.uppercase {
  text-transform: uppercase;
}


