.navbar-container{
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center; 
  /* margin-top: 15px; */
}
.navbar-subContainer{
  display: flex;
  /* background-color: #3498db; */
  /* flex-direction: row; */
}
.navbar-link{
  font-size: 2vw;
  transition: all 0.3s ease;
}
.navbar-item{
  text-decoration: none;
  
  margin-left: 2vw;
  margin-right: 2vw;
  color: #ffffff;
  font-family: Resolve;

}

.navbar-item:hover .navbar-link{
  color:#3498db ;
  color: transparent; /* Establece el color de relleno en transparente */
  -webkit-text-stroke: 2px #ffffff; /* Ancho y color del contorno */
  -webkit-font-smoothing: antialiased; /* Mejora la suavidad del contorno en ciertos navegadores */
}

@media (max-width: 768px) {
  /* Media Query para dispositivos con un ancho mínimo de 768px (tabletas y PCs) */
  .navbar-link {
    font-size: 0.9rem; /* Tamaño más grande para PCs */
  }
  .navbar-item{ 
    margin-left: 1rem;
    margin-right: 1rem;
  }
}