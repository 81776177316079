.settings-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
.settings-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 10vh;
  position: fixed;
  background-color: black;

}
.logout-button {
  /* width: 100%; */
  padding: 0.5vh;
  background-color: #df0b0b;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 2vh;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 1vh;
}

.logout-button:hover {
  background-color: #df0b0bc6;
}

.invisible-logout-button{
  padding: 0.5vh;
  font-size: 2vh;
  color: transparent;
  pointer-events: none;

}

.settings-sidevar{
  width: 20vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.795);
  padding-top: 10vh;
  position: fixed;
}

.settings-selector li{
  color: #fff;
  /* font-weight: ; */
  font-size: 1.8vw;
  cursor: pointer;
}
.settings-selector{
  padding-left: 1vw;
}

.bold-text{
  font-weight: bold;
}
