.site-hamburger {
  height: 1.5vh;
  width: 40px;
  position: fixed;
  top: 4%;
  right: 3%;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 1); /* Color de fondo con transparencia */
  mix-blend-mode: difference; /* Mezcla de colores en modo diferencia */
  filter: invert(1); /* Inversión de colores en el fondo */
  z-index: 602;
  transition: height  0.4s ease, background-color 0.4s ease;
}
.clicked {
  height: 40px;
  /* background-color: rgb(0, 0, 0); */
  
}

.container{
  flex: 1;
  /* background-color: black; */
  position:fixed;
  height: 100%;
  width: 100%;
  /* background-color: rgb(255, 255, 255); */
  z-index: 501;
  padding-left: 7rem;
  padding-top: 10vh;
  /* box-sizing: border-box; */
  transform: translate(150%, 0%);
  transition: transform 0.5s ease;
}
.container2 {
  flex: 1;
  /* background-color: black; */
  position:fixed;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 500;
  transform: translate(100%, 0%);
  transition: transform 0.5s ease;
  
}
.text-drawer {
  flex: 1; /* Distribuimos el espacio disponible de manera equitativa */
  margin: 0;
  font-family: Resolve;

  font-size: 2rem;
  text-decoration: none;
  color: black;
  transition: color 0.5s ease;
  text-transform: uppercase;
  line-height: 2.5rem;
  /* transform: translate(-100%, 0%); */
}
/* .item-drawer{
  transform: translate(-100%, 0%);

} */

.item-drawer:hover .text-drawer {
  color: transparent; /* Establece el color de relleno en transparente */
  -webkit-text-stroke: 2px rgb(0, 0, 0); /* Ancho y color del contorno */
  -webkit-font-smoothing: antialiased; /* Mejora la suavidad del contorno en ciertos navegadores */
}

.container.expand-container,
.container2.expand-container{
  transform: translate(0%, 0%);
  transform: transform 0.5s ease;
}

@media (min-width: 768px) {
  /* Media Query para dispositivos con un ancho mínimo de 768px (tabletas y PCs) */
  .text-drawer {
    font-size: 5rem; /* Tamaño más grande para PCs */
    line-height: 4rem;
  }
}