.director-page-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: tomato; */
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.director-page-name {
  /* font-size: calc(100vw / 10); */
  font-family: Resolve;
  white-space: nowrap;
  color: white;
}