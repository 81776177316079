.reel-container{
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;


}

.black-background{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
  z-index: -30000000;
}

.reel-director{
  font-family: Resolve;
  color: transparent;
  -webkit-text-stroke: 2px #ffffff; /* Ancho y color del contorno */
  -webkit-font-smoothing: antialiased; /* Mejora la suavidad del contorno en ciertos navegadores */
}

/* .reel-container {
  poin
} */

.vimeo-player-container {
  position: relative;
  width: 60vw;
  min-width: 700px;
  height: auto;
  /* Aspect ratio 16:9 */
  /* overflow: hidden; */

}

#vimeo-player {
  position: relative;
  top: 0;
  left: 0;
  
  z-index: 4;

}

@media (max-width: 768px) {
  /* Media Query para dispositivos con un ancho mínimo de 768px (tabletas y PCs) */
  .vimeo-player-container {
    width: 95vw;
    min-width: 100px;
  }
}