.notfound-container {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Resolve;
  color: #ffffff;

  font-weight: 100;
  opacity: 0;
  transition: all 0.3s ease;
}

.notfound-back{
  text-decoration: none;
  color: #ffffff;
}

.show-notfound{
  opacity: 1;
}
