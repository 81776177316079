.selected-video-container{
  background-color: black;
}

.video-preview-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  font-family: Resolve;
  
}
.video-preview{
  position: absolute;
  object-fit: cover;
  height: 100vh;
  width: 100vw;
}
.video-text{
  z-index: 3;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); /* Agrega la sombra aquí */
  text-align: center;
}
.ficha-tecnica{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  font-family: Resolve;
  margin-top: 7vh;
  margin-bottom: 10vh;
  margin-left: 10vw;
  margin-right: 10vw;
  /* text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.5); Agrega la sombra aquí */
  color: white;
  
}

.video-youtube-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 100vh;
  font-family: Resolve;
  background-color: black;
}

.credits{
  font-family: Resolve;
}

.video-youtube{
  position: absolute;
}

@media (max-width: 768px) {
  /* Media Query para dispositivos con un ancho mínimo de 768px (tabletas y PCs) */
  .ficha-tecnica {
  font-size: small;
  }
  .video-client{
    font-size: 3vw;
  }
}